<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="卡号">
                <el-input v-model="search.kaHao" size="small"/>
            </x-search-item>
            <x-search-item label="姓名">
                <el-input v-model="search.xingMing" size="small"/>
            </x-search-item>
            <x-search-item label="手机号">
                <el-input v-model="search.shouJiHao" size="small"/>
            </x-search-item>
            <!--            <x-search-item label="会员类型" >-->
            <!--                <x-selector-one  v-model="search.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>-->
            <!--            </x-search-item>-->
            <x-search-item label="卡状态">
                <x-selector-one v-model="search.kaZhuangTai" dictType="T_KA_ZT"/>
            </x-search-item>
            <x-search-item label="开卡时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.kaiKaSJBegin" size="small" type="datetime"/>
                <el-date-picker v-model="search.kaiKaSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="所属门店">
                <!--                <el-input v-model="search.suoShuMenDian" size="small" />-->
                <el-select v-model="search.suoShuMenDian" placeholder="请选择" size="small">
                    <el-option
                            v-for="item in menDianList"
                            :key="item.menDianMC"
                            :label="item.menDianMC"
                            :value="item.menDianMC">
                    </el-option>
                </el-select>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>

                <el-button type="primary" size="mini" @click="exportExcel"
                           style="background-color: #f0f0f0;color: black;border: #8f8f9d 1px solid;margin-left: 10px;">
                    导出会员信息>>
                </el-button>
                <!--                    <label style="margin-left: 30px;font-weight: bold">导入会员信息：</label>-->
                <!--                    <div id="uploadFile" style="line-height: 35px">-->
                <!--                        <input type="file" ref="upload"  accept=".xls,.xlsx" >-->
                <!--                    </div>-->
                <!--                    <el-button type="primary" size="mini">-->
                <!--                        <a  href="./static/hygl/会员信息.xlsx" style="color: #fff" download="会员信息.xlsx">下载导入模板</a>-->
                <!--                    </el-button>-->


                <el-button type="primary" size="mini">
                    <label style="font-weight: bold" @click="daoRuShow">导入会员信息</label>
                </el-button>
            </div>
        </div>
        <!--        概览区-->
        <div class="">
            <el-row :gutter="12" style="text-align: center">
                <el-col :span="4">
                    <el-card shadow="hover">
                        总人数<br> {{huiyuanTotal}}
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover">
                        总金额<br>{{yueTotal}}
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover">
                        总积分<br> {{jifenTotal}}
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="kaHao" label="卡号"/>
            <el-table-column width="180" prop="xingMing" label="姓名"/>
            <el-table-column width="180" prop="shouJiHao" label="手机号"/>
            <el-table-column width="180" prop="huiYuanShengRi" label="会员生日"/>
            <el-table-column width="180" prop="huiYuanLeiXing" label="会员类型">
                <x-dict-show slot-scope="{row}" :code="row.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>
            </el-table-column>
            <!--            <el-table-column width="180" prop="plusZheKou" label="plus折扣" />-->
            <el-table-column width="180" prop="huiYuanDJ" label="会员等级"/>
            <el-table-column width="180" prop="leiJiJiFen" label="累计积分"/>
            <el-table-column width="180" prop="shengYuJiFen" label="剩余积分"/>
            <el-table-column width="180" prop="shengYuJinE" label="剩余金额"/>
            <el-table-column width="180" prop="chongZhiJinE" label="充值金额"/>
            <el-table-column width="180" prop="zengSongJinE" label="赠送金额"/>
            <el-table-column width="180" prop="huiYuanBiaoQian" label="会员标签"/>
            <el-table-column width="180" prop="kaZhuangTai" label="卡状态">
                <x-dict-show slot-scope="{row}" :code="row.kaZhuangTai" dictType="T_KA_ZT"/>
            </el-table-column>
            <el-table-column width="180" prop="kaiKaSJ" label="开卡时间"/>
            <el-table-column width="180" prop="shiFuZhuXiao" label="是否注销">
                <x-dict-show slot-scope="{row}" :code="row.shiFuZhuXiao" dictType="T_YES_OR_NO"/>
            </el-table-column>
            <el-table-column width="180" prop="suoShuMenDian" label="所属门店"/>
            <el-table-column width="180" prop="caoZuoRen" label="开卡人"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-dropdown-item>
                        <el-button type="success" size="mini" round @click="xiangQingShow(row.id)">详细信息<i class="el-icon-tickets el-icon--right"></i></el-button>
                    </el-dropdown-item>
                    <el-dropdown>
                          <span class="el-dropdown-link">
                            更多操作<i class="el-icon-edit-outline el-icon--right"></i>
                          </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-button v-if="row.shiFuZhuXiao!==yesOrNo.YES.key"  size="mini" round @click="toUpdateXX(row.id)" icon="el-icon-edit">编辑</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popconfirm v-if="row.kaZhuangTai===kaZT.ZC.key" title="是否确认冻结?" @confirm="dongjie(row.id)">
                                    <el-button size="mini" round slot="reference" icon="el-icon-lock">冻结</el-button>
                                </el-popconfirm>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popconfirm v-if="row.kaZhuangTai===kaZT.YDJ.key" title="是否确认解冻?" @confirm="jiedong(row.id)">
                                    <el-button size="mini" round slot="reference" icon="el-icon-unlock">解冻</el-button>
                                </el-popconfirm>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popconfirm v-if="row.shiFuZhuXiao!==yesOrNo.YES.key" icon-color="red" title="是否确认销卡?" @confirm="xiaoka(row.id)">
                                    <el-button  size="mini" round slot="reference" icon="el-icon-turn-off">销卡</el-button>
                                </el-popconfirm>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button v-if="row.shiFuZhuXiao!==yesOrNo.YES.key"  size="mini" round @click="editPwd(row.id)" icon="el-icon-refresh-left">修改密码</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popconfirm title="数据删除后不可恢复，确认删除吗？" icon-color="red" @confirm="handleDelete(row.id)">
                                    <el-button  size="mini" round slot="reference" icon="el-icon-delete">删除</el-button>
                                </el-popconfirm>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button v-if="row.kaZhuangTai===kaZT.ZC.key"  size="mini" round @click="myYhq(row.id)" icon="el-icon-present">优惠券</el-button>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-popconfirm title="确定转化为PLUS会员吗？" icon-color="red" @confirm="changeToPlus(row.id)">
                                    <el-button v-if="row.kaZhuangTai===kaZT.ZC.key" size="mini" round slot="reference" icon="el-icon-user">转PLUS会员</el-button>
                                </el-popconfirm>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>


                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
        <xiang-qingxx ref="xiangQingxx"/>
        <XinXiDaoRu ref="xinXiDaoRu"/>
        <!--        优惠券-->
        <my-yhq ref="myYhq"/>
        <!--        修改密码-->
        <pwd-update ref="pwdUpdate"/>
        <!--        基础信息编辑-->
        <hui-yuan-x-x-update ref="huiYuanXXUpdate" @submitted="refresh"/>


    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanXX";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/hyxx/HuiYuanXXEdit";
    import Detail from "@/view/hygl/hyxx/HuiYuanXXDetail";
    import XiangQingxx from "@/view/hygl/hyxx/XiangQingxx";
    import XinXiDaoRu from "@/view/hygl/hyxx/XinXiDaoRu";
    import {ruleBuilder, isDigits, validMobilePhone} from "@/util/validate";
    import {DICT_TYPE_BDCJ, DICT_TYPE_HUI_YUAN_LX, DICT_TYPE_KA_ZT, DICT_YES_OR_NO} from "@/util/constant";
    import XLSX from 'xlsx';
    import HuiYuanDJSelector from "@/view/selector/HuiYuanDJSelector";
    import MyYhq from "@/view/hygl/hyxx/MyYhq";
    import pwdUpdate from "@/view/hygl/hyxx/pwdUpdate";
    import HuiYuanXXUpdate from "@/view/hygl/hyxx/HuiYuanXXUpdate";
    import XHyTagSelector from "@/view/hygl/hybq/XHyTagSelector";

    export default {
        name: "HuiYuanXXList",
        mixins: [XTableBase],
        components: {Edit, Detail, XiangQingxx, XinXiDaoRu, HuiYuanDJSelector, MyYhq, XHyTagSelector, pwdUpdate, HuiYuanXXUpdate},
        data() {
            this.refreshService = service.list;
            this.service = service;
            this.exportService = service.excelIExport;
            let {required, mobilePhone, positiveZeroTwo, digits, isDigits} = ruleBuilder;
            return {
                exportData: [],
                // hylxList:DICT_TYPE_HUI_YUAN_LX,
                kaZT: DICT_TYPE_KA_ZT,
                yesOrNo: DICT_YES_OR_NO,
                search: {
                    kaHao: '',
                    xingMing: '',
                    shouJiHao: '',
                    huiYuanLeiXing: '1',
                    kaZhuangTai: '',
                    kaiKaSJBegin: '',
                    kaiKaSJEnd: '',
                    suoShuMenDian: '',
                },
                //概览数据
                huiyuanTotal: '',
                yueTotal: '',
                jifenTotal: '',
                //门店信息
                menDianMC: "",
                userName: "",
                drMenDian: '',
                isShanghu: false,
                //会员信息
                hyxx: {},
                hydjList: [
                    {
                        classification: "",
                        createTime: "",
                        creatorId: "",
                        huiYuanDJ: "",
                        huiYuanMC: "",
                        id: "",
                        ownerAreaCode: "",
                        ownerDeptCode: "",
                        ownerDeptName: "",
                        ownerOrganCode: "",
                        ownerOrganName: "",
                        updateTime: "",
                        updaterId: "",
                        xiangShouZheKou: null,
                        zuiDiJiFen: null,
                    }
                ],
                //充值表单

                //修改密码表单
                editPwdFormVisible: false,
                editPwdform: {
                    pwd: "",
                    id: '',
                },


                //修改信息表单
                editxxFormVisible: false,
                rules: {
                    shouJiHao: [required(), mobilePhone()],
                    jinE: [required()],
                    dengJi: [required()],
                    jiFen: [required()],
                    zk: [required(), positiveZeroTwo()],
                    fangShi: [required()],
                    shifu: [{required: true, message: '充值金额不能为空', trigger: 'blur'}, digits()],
                    zengsong: [{required: true, message: '赠送金额不能为空', trigger: 'blur'}, digits()],
                    pwd: [{min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur'},],
                    huiYuanBQ: [],
                },
                editxxform: {
                    id: '',
                    shouJiHao: '',
                    jinE: null,
                    dengJi: null,
                    jiFen: null,
                    chongZhiJinE: null,
                    shengYuJinE: null,
                    huiYuanBQ: [],
                },
                formLabelWidth: '120px',
                xxdrFormVisible: false,
                menDianList: []
            }
        },
        mounted() {
            // console.log(service.total())
            this.editxxform.shengYuJinE = this.editxxform.chongZhiJinE + this.editxxform.jinE
            service.total(DICT_TYPE_HUI_YUAN_LX.PLUSHY.key).then(res => {
                console.log(res.data)
                this.huiyuanTotal = res.data.huiYuanTotal
                this.jifenTotal = res.data.jiFenTotal
                this.yueTotal = res.data.yuETotal
                // console.log(this.huiyuanTotal)
                // console.log(this.jifenTotal)
                // console.log(this.yueTotal)
            })
            service.hydjlist().then(res => {
                if (res.code === 200) {
                    console.log(res.data)
                    this.hydjList = res.data;
                }
            })

            service.excelIExport().then(res => {
                if (res.code === 200) {
                    this.exportData = res.data;
                }
            })

            // 获取当前门店和操作人
            menDianXXservice.getCurrentMenDianXXFromRedis().then(res => {
                console.log(this.$store.getters.user);
                if (!res.data) {
                    menDianXXservice.checkboxList().then(res => {
                        console.log(res);
                        if (res.code === 200) {
                            this.menDianList = res.data
                        }
                    });
                    this.userName = this.$store.getters.user.name;
                    this.menDianMC = "商户"
                    this.isShanghu = true;
                } else {
                    this.userName = this.$store.getters.user.name;
                    this.menDianMC = res.data.menDianMC;
                    this.drMenDian = res.data.menDianMC;
                    console.log(this.drMenDian);
                    console.log(this.userName);
                    console.log(this.menDianMC)
                }
            })

            //获取所有门店
            menDianXXservice.checkboxList().then(res => {
                console.log(res);
                if (res.code === 200) {
                    this.menDianList = res.data
                }
            });


        },
        methods: {
            daoRuShow() {
                this.$refs.xinXiDaoRu.dalogShow();
            },

            exportExcel() {
                const data = this.exportData;
                console.log(data)
                const ws = XLSX.utils.aoa_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, '会员信息.xlsx');
            },
            xiangQingShow(id) {
                this.$refs.xiangQingxx.showxx(id);
                console.log(id)
            },


            dongjie(id) {
                // console.log(this.form.id)
                let data = {
                    id: id,
                    caoZuoRenYuan: this.userName,
                }
                // console.log("123456")
                // console.log(data)
                service.dongjie(data).then(res => {
                    // console.log(res)
                    if (res.code == 200) {
                        this.refresh()
                        this.$message.success("冻结成功")
                    } else {
                        this.$message.warning("冻结失败")
                    }
                })
            },
            jiedong(id) {
                let data = {
                    id: id,
                    caoZuoRenYuan: this.userName,
                }
                service.jiedong(data).then(res => {
                    // console.log(res.data)
                    if (res.code == 200) {
                        this.refresh()
                        this.$message.success("解冻成功")
                    } else {
                        this.$message.warning("解冻失败")
                    }
                })
            },
            xiaoka(id) {
                let data = {
                    id: id,
                    caoZuoRenYuan: this.userName,
                    menDian: "",
                    cj:DICT_TYPE_BDCJ.MDHT.key,
                };
                service.xiaoka(data).then(res => {
                    console.log(res);
                    if (res.code === 200) {
                        this.refresh();
                        this.$message.success("销卡成功")
                    } else {
                        this.$message.warning("销卡失败")
                    }
                })
            },

            toUpdateXX(id){
                this.$refs.huiYuanXXUpdate.editxxshow(id)
            },
            editxxshow(id) {
                this.editxxFormVisible = true;
                this.editxxform.id = id
                service.getUpdate(id).then(res => {
                    if (res.code === 200) {
                        console.log(res.data)
                        this.editxxform.shouJiHao = res.data.shouJiHao;
                        this.editxxform.dengJi = res.data.huiYuanDJ;
                        this.editxxform.jinE = res.data.zengSongJinE;
                        this.editxxform.jiFen = res.data.shengYuJiFen;
                        this.editxxform.shengYuJinE = res.data.shengYuJinE;
                        this.editxxform.chongZhiJinE = res.data.chongZhiJinE;
                        if (res.data.huiYuanBQ) {
                            this.editxxform.huiYuanBQ = res.data.huiYuanBQ;
                        } else {
                            this.editxxform.huiYuanBQ = [];
                        }
                        this.editxxform.id = id;
                    }
                })
            },
            editxx() {
                var b = validMobilePhone(this.editxxform.shouJiHao);
                if (b) {
                    this.editxxFormVisible = false;
                    var data = {
                        shouJiHao: this.editxxform.shouJiHao,
                        dengJi: this.editxxform.dengJi,
                        jinE: this.editxxform.jinE,
                        jiFen: this.editxxform.jiFen,
                        id: this.editxxform.id,
                        caoZuoRenYuan: this.userName,
                        bianDongMenDian: this.menDianMC,
                        huiYuanBQ: this.editxxform.huiYuanBQ,
                    };
                    service.updateXX(data).then(res => {
                        if (res.code === 200) {
                            console.log(res)
                            this.$message.success("修改成功")
                            this.refresh()
                        } else {
                            this.$message.error("修改失败")
                        }
                    })

                } else {
                    this.$message.warning("手机号格式错误")
                }

            },
            editPwd(id){
                this.$refs.pwdUpdate.editPwdshow(id)
            },
            myYhq(id) {
                this.$refs.myYhq.myYhqShow(id)
            },
            changeToPlus(id) {
                service.changeToPlus(id).then(res => {
                    if (res.code === 200)
                        this.$message.success("已成功转为PLUS会员！")
                    this.refresh();
                })

            },
            handleClear() { //清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.search.huiYuanLeiXing= '1'
            },

        }

    }
</script>

<style scoped>
</style>

import { render, staticRenderFns } from "./HuiYuanXXList.vue?vue&type=template&id=44493327&scoped=true&"
import script from "./HuiYuanXXList.vue?vue&type=script&lang=js&"
export * from "./HuiYuanXXList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44493327",
  null
  
)

export default component.exports